// File: App.scss
// This file contains all styles related to the site/application.
@use "./assets/scss/utilities" as *;

/* STYLES GO HERE */
html * {
  // font-family: Tajawal !important;
  margin: 0;
  padding: 0;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}
body {
  background: $pure_white !important;
}

a {
  text-decoration: none;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-width: 4px;
  scrollbar-color: #2897f3 #e5edf7;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  z-index: 120;
}

*::-webkit-scrollbar-track {
  background: #e5edf7;
}

*::-webkit-scrollbar-thumb {
  background-color: #2897f3;
  border-radius: 0;
  border: 0px solid #e5edf7;
}
.maintenace {
  font-family: "Tajawal" !important;
}
#icon {
  font-size: 130px !important;
  color: #f0e445 !important ;
}

.rtl {
  input {
    text-align: right;
  }
}
